<template>
  <div class="thinkcase-container">
    <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
      <van-swipe-item v-for="item in thinkCaseList" :key="item.id">
        <van-image
          fit="cover"
          :src="item.picture"
          width="100%"
          height="100%"
        ></van-image>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'ThinkCase',
  data() {
    return {
      thinkCaseList: [
        { id: 1, picture: require('@/assets/images/think/think-case1.png') },
        { id: 2, picture: require('@/assets/images/think/think-case2.png') },
        { id: 3, picture: require('@/assets/images/think/think-case3.png') },
        { id: 4, picture: require('@/assets/images/think/think-case4.png') },
        { id: 5, picture: require('@/assets/images/think/think-case5.png') }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-swipe__indicators {
  .van-swipe__indicator {
    position: relative;
    width: 6px;
    height: 6px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #010343;
    border-radius: 50%;
  }
  i.van-swipe__indicator--active {
    width: 12px;
    height: 12px;
    background: #000 !important;
    border: 1px solid #010343;
    transform: translateY(-20%);
  }
}
</style>
