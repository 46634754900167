<template>
  <div class="think-process-container">
    <div class="title">智囊团陪跑计划服务流程</div>
    <ul class="content">
      <li>
        <dl>
          <dt><span class="icon">01</span>咨询准备阶段</dt>
          <dd>
            1、调研:企业填写《智囊团信息采集报告》, 提
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            供企业营销相关资料。
          </dd>
          <dd>
            2、定向:确定本次《智囊团回服务协议》咨询
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            服务范围。
          </dd>
          <dd>
            3、立项:成立咨询项目小组统筹安排咨询推动
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            工作。
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt><span class="icon">02</span>敏捷咨询阶段</dt>
          <dd>
            1、根据《信息采集报告》,咨询团队内部研讨
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;并设立出基础方案框架。
          </dd>
          <dd>
            2、通过基于9+9模型的敏捷咨询,双方确定方案
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;逻辑、内容与执行细节,达成阶段提案。
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt><span class="icon">03</span>敏捷咨询阶段</dt>
          <dd>
            1、双方沟通确定阶段方案所需配套文件(合同
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;协议、机制)。
          </dd>
          <dd>
            2、配套协议的起草、律师审核、校对与最终输
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;出。
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt><span class="icon">04</span>方案落地阶段</dt>
          <dd>
            1、审核:方案以及文件再次进行审核,确定细节
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            以及推行流程。
          </dd>
          <dd>
            2、帮扶:协助完善方案落地进程中所涉及的推
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            动逻辑、方法和工具。
          </dd>
          <dd>
            3、复盘:每阶段对方案推动过程中客户的反馈
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            做系统收集,系统反馈。
          </dd>
          <dd>
            4、优化:根据市场需求对方案进行一定比例的
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            调整优化。
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt><span class="icon">05</span>跟进升级阶段</dt>
          <dd>
            1、跟踪:1年半咨询项目组免费跟进。
          </dd>
          <dd>
            2、升级:1年半方案免费优化升级。
          </dd>
        </dl>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ThinkProcess'
}
</script>

<style lang="less" scoped>
.think-process-container {
  padding: 60px 20px 0;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #010343;
    line-height: 28px;
    text-align: center;
    margin-bottom: 28px;
  }
  .content {
    li {
      margin-bottom: 23px;
    }
    dl {
      background: #f7f9fd;
    }
    .icon {
      display: inline-block;
      width: 34px;
      height: 41px;
      background: #1651dd;
      border-radius: 0px 0px 37px 37px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 41px;
      text-align: center;
      transform: translateY(5px);
      margin-right: 4px;
    }
    dt {
      width: 290px;
      font-size: 14px;
      font-weight: 700;
      color: #010343;
      line-height: 27px;
    }
    dd {
      padding-left: 38px;
      font-size: 14px;
      font-weight: 500;
      color: #010343;
      line-height: 27px;
    }
  }
}
</style>
