<template>
  <div class="thinkteam-container">
    <h3 class="title">智囊团咨询团队</h3>

    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="swiper-item"
        v-for="item in think_team_list"
        :key="item.id"
      >
        <img :src="item.picture" alt="" />
      </swiper-slide>
    </swiper>

    <!-- <QbMore top="45"></QbMore> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// import QbMore from '@/components/qb-more.vue'
export default {
  name: 'thinklteam',
  components: {
    swiper,
    swiperSlide
    // QbMore
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2000
        }
      },
      think_team_list: [
        {
          id: 1,
          picture: require('@/assets/images/think/think_team_1.png')
        },
        {
          id: 2,
          picture: require('@/assets/images/think/think_team_2.png')
        },
        {
          id: 3,
          picture: require('@/assets/images/think/think_team_3.png')
        },
        {
          id: 4,
          picture: require('@/assets/images/think/think_team_4.png')
        },
        {
          id: 5,
          picture: require('@/assets/images/think/think_team_5.png')
        },
        {
          id: 6,
          picture: require('@/assets/images/think/think_team_6.png')
        },
        {
          id: 7,
          picture: require('@/assets/images/think/think_team_7.png')
        },
        {
          id: 8,
          picture: require('@/assets/images/think/think_team_8.png')
        },
        {
          id: 9,
          picture: require('@/assets/images/think/think_team_9.png')
        },
        {
          id: 10,
          picture: require('@/assets/images/think/think_team_10.png')
        },
        {
          id: 11,
          picture: require('@/assets/images/think/think_team_11.png')
        },
        {
          id: 12,
          picture: require('@/assets/images/think/think_team_12.png')
        },
        {
          id: 13,
          picture: require('@/assets/images/think/think_team_13.png')
        },
        {
          id: 14,
          picture: require('@/assets/images/think/think_team_14.png')
        },
        {
          id: 15,
          picture: require('@/assets/images/think/think_team_15.png')
        },
        {
          id: 16,
          picture: require('@/assets/images/think/think_team_16.png')
        },
        {
          id: 17,
          picture: require('@/assets/images/think/think_team_17.png')
        },
        {
          id: 18,
          picture: require('@/assets/images/think/think_team_18.png')
        },
        {
          id: 19,
          picture: require('@/assets/images/think/think_team_19.png')
        },
        {
          id: 20,
          picture: require('@/assets/images/think/think_team_20.png')
        },
        {
          id: 21,
          picture: require('@/assets/images/think/think_team_21.png')
        },
        {
          id: 22,
          picture: require('@/assets/images/think/think_team_22.png')
        },
        {
          id: 23,
          picture: require('@/assets/images/think/think_team_23.png')
        },
        {
          id: 24,
          picture: require('@/assets/images/think/think_team_24.png')
        },
        {
          id: 25,
          picture: require('@/assets/images/think/think_team_25.png')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.thinkteam-container {
  padding-top: 62px;
  padding-bottom: 31px;
  background: #f7f9fd;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    margin-bottom: 39px;
  }
  .swiper-item {
    width: 268px;
    height: 468px;
    background: #00123f;
    img {
      object-fit: cover;
    }
  }
}
</style>
