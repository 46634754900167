<template>
  <div class="module-container">
    <h3 class="module-title">智囊团3.0 服务模块</h3>
    <div class="content">
      <van-image
        width="100%"
        height="100%"
        :src="require('@/assets/images/think/think-service.png')"
      />
    </div>
    <div class="valueadd">
      <h3 class="valueadd-title">增值服务</h3>
      <van-image
        width="100%"
        height="100%"
        :src="require('@/assets/images/think/think-valueadd.png')"
      >
      </van-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThinkModule'
}
</script>

<style lang="less" scoped>
.module-title {
  font-size: 24px;
  font-weight: 700;
  color: #010343;
  line-height: 33px;
  margin-top: 43px;
  margin-left: 40px;
  margin-bottom: 10px;
}
.valueadd {
  margin-bottom: 88px;
}
.valueadd-title {
  width: 96px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #010343;
  line-height: 33px;
  margin: 58px 0 24px 21px;
}
</style>
