<template>
  <div class="thinkContent-container">
    <div class="title"></div>
    <div class="content"></div>
    <div class="line"></div>
    <dl class="promise">
      <dt><span>*</span>前提：智囊团陪跑计划需经过筛选审核:</dt>
      <dd>1.确保您的公司及项目正向运作;</dd>
      <dd>2.方案给出后有执行力去启动;</dd>
      <dd>3.有资源(未做过资金盘，直销等损伤人脉)</dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'ThinkContent'
}
</script>

<style lang="less" scoped>
.thinkContent-container {
  .title {
    width: 316px;
    height: 115px;
    background: url(~@/assets/images/think/think-content-title.png);
    background-size: cover;
    margin: 41px auto 20px;
  }
  .content {
    width: 335px;
    height: 988px;
    background: url(~@/assets/images/think/think-content.png);
    background-size: cover;
    margin: 0px auto 68px;
  }
  .line {
    width: 335px;
    border: 1px solid #010343;
    margin: 0px auto 9px;
  }
  dl {
    dt {
      font-size: 18px;
      font-weight: 700;
      color: #313131;
      line-height: 25px;
      padding-left: 14px;
      margin-bottom: 23px;
    }
    span {
      color: #ff0000;
    }
    dd {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      padding-left: 78px;
      padding-right: 32px;
    }
  }
}
</style>
