<template>
  <div class="think-container">
    <div class="video">
      <video
        src="http://res.quanbangcloud.com/videos/00/00208b1e26cc4ceca4bb042e9f7f7b47.mp4"
        poster="@/assets/images/video_cover.png"
        loop="loop"
        ref="video"
        :controls="controls"
        @click="handlePlay(false)"
      ></video>
    </div>
    <div class="play_btn" @click="handlePlay(true)" v-if="showPlayBtn">
      <img src="@/assets/images/fission/play_btn.png" alt="" />
    </div>
    <div class="introduction">
      经过泉邦帮助服务的企业几乎涵盖全部业态，包括美业、服务、健康行业、零售行业、服装行业、母婴行业、教育行业等等，在各行各业都能取得超出预期的成效。
    </div>
  </div>
</template>

<script>
export default {
  name: 'thinkVideo',
  data() {
    return {
      showPlayBtn: true,
      controls: false
    }
  },
  methods: {
    handlePlay(flag) {
      if (flag) {
        this.$refs.video.play()
        this.controls = true
        this.showPlayBtn = false
        return
      }
      this.$refs.video.pause()
      this.showPlayBtn = true
      this.controls = false
    }
  }
}
</script>

<style lang="less" scoped>
.think-container {
  padding-top: 84px;
  padding-bottom: 58px;
  position: relative;
  .video {
    width: 335px;
    height: 182px;
    margin: 0 auto;
    video {
      object-fit: cover;
      width: 100%;
    }
  }
  .play_btn {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    width: 26px;
    height: 26px;
  }
  .introduction {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 19px 21px;
  }
}
</style>
