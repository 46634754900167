<template>
  <div class="think-container">
    <!-- navbar -->
    <qb-navbar title="智囊团"></qb-navbar>
    <!-- title -->
    <qb-title
      title="智囊团陪跑计划"
      subtitle="Quanbang Group Product"
    ></qb-title>
    <!-- think-video -->
    <think-video></think-video>
    <!-- team -->
    <think-team></think-team>
    <!-- think content -->
    <think-content></think-content>
    <!-- think-process -->
    <think-process></think-process>
    <!-- think module -->
    <think-module></think-module>
    <!-- think case -->
    <think-case></think-case>
    <!-- qb-application -->
    <qb-application></qb-application>
  </div>
</template>

<script>
import ThinkVideo from './components/think-video.vue'
import ThinkTeam from './components/think-team.vue'
import ThinkContent from './components/think-content.vue'
import ThinkProcess from './components/think-process.vue'
import ThinkModule from './components/think-module.vue'
import ThinkCase from './components/think-case.vue'
export default {
  name: 'Think',
  components: {
    ThinkVideo,
    ThinkTeam,
    ThinkContent,
    ThinkProcess,
    ThinkModule,
    ThinkCase
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped></style>
